import { type PlayerNode } from "../GameLevelBinaryTree/PlayerNode";
import { type LevelNode } from "./types";

export function getAccountLevelLookupDocKey(from: string, levelNumber: number) {
  return `${from}-level-${levelNumber}`.toLowerCase();
}

export function parsePlayerNode(node: PlayerNode): LevelNode {
  return {
    cyclesCount: node.cyclesCount,
    investment: node.investment,
    isCycleComplete: node.isCycleComplete,
    left: node.left?.nodeId ?? null,
    nodeId: node.nodeId,
    right: node.right?.nodeId ?? null,
    startPosition: node.startPosition,
    totalReward: node.totalReward,
    txReceipt: node.txReceipt,
    referralCode: node.referralCode,
  };
}
