import { type Config } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { bsc } from "viem/chains";
import { type QueryClient } from "react-query";
import { type Web3Modal } from "@web3modal/wagmi/dist/types/src/client";

type WagmiConfig = Config<any, any> & { queryClient: QueryClient };

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];

export class WalletConnectService {
  wagmiConfig: WagmiConfig;
  web3Modal: Web3Modal;

  constructor() {
    if (!process.env.GATSBY_WALLET_CONNECT_ID) {
      throw new Error("GATSBY_WALLET_CONNECT_ID not set");
    }

    const projectId = process.env.GATSBY_WALLET_CONNECT_ID;

    this.wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata }) as WagmiConfig;

    this.web3Modal = createWeb3Modal({
      wagmiConfig: this.wagmiConfig,
      projectId,
      chains,
      defaultChain: chains[0],
    });
  }

  public getWagmiConfig() {
    return this.wagmiConfig;
  }
}
