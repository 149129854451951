/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { type ReactNode } from "react";
import { hasWindow } from "../../utils/windowUtils";
import { AFFILIATE_ROUTE, WEBSITE_SHARE_CODE } from "../../constants/websiteConstants";
import { Stack } from "@mui/material";
import { MOBILE_PAGE_PADDING } from "../../constants/uiConstants";
import { useTranslation } from "react-i18next";
import { ALLOW_ADMIN_ACCESS_KEY, REFERRAL_CODE_KEY } from "../../constants/localStorageConstants";
import CheckSvg from "../../assets/svg/check.svg";

interface ReferralLinkValidatorProps {
  children: ReactNode;
}

const styles = {
  container: css`
    padding: 0 ${MOBILE_PAGE_PADDING}px;
    overflow: hidden;
    text-align: center;

    p {
      font-family: "Orbitron", "Tektur", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: white;
      margin-top: 8px;
    }

    span {
      font-family: "Roboto", sans-serif;
      color: #efedfd99;
      font-size: 10px;
      margin-top: 10px;
    }

    svg {
      height: 60px;
      fill: #00af88;
    }
  `,
};

export const ReferralLinkValidator = ({ children }: ReferralLinkValidatorProps) => {
  const { t } = useTranslation();

  const url = window.location.pathname;

  function redirect(path: string) {
    setTimeout(() => {
      window.location.href = path;
    }, 3000);
  }

  function renderConfirmation(message: string) {
    return (
      <Stack
        width={"100%"}
        height={"calc(100svh - 16px)"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack css={styles.container} width={"100%"}>
          <CheckSvg />
          <p>{message}</p>
          <span>{t("You will be redirected")}</span>
        </Stack>
      </Stack>
    );
  }

  if (!hasWindow()) {
    return children;
  }

  if (url.startsWith(`/${WEBSITE_SHARE_CODE}`)) {
    redirect("/");
    return renderConfirmation(t("Referral Link Accepted"));
  }

  if (url.startsWith(`/${process.env.GATSBY_ALLOW_ADMIN_ACCESS}`)) {
    localStorage.setItem(ALLOW_ADMIN_ACCESS_KEY, "true");
    redirect("/admin");
    return renderConfirmation(t("Admin Pass Accepted"));
  }

  if (url.startsWith(AFFILIATE_ROUTE)) {
    const referralCode = url.replace(AFFILIATE_ROUTE, "");
    localStorage.setItem(REFERRAL_CODE_KEY, referralCode);
    redirect("/");
    return renderConfirmation(t("Referral Link Accepted"));
  }

  return children;
};
