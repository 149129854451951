import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { WalletConnectService } from "./src/services/WalletConnectService/WalletConnectService";
import { RequestGateKeeper } from "./src/components/RequestGateKeeper/RequestGateKeeper";
import { StateHydrator } from "./src/components/StateHydrator/StateHydrator";
import { ReferralLinkValidator } from "./src/components/ReferralLinkValidator/ReferralLinkValidator";
import { WagmiConfig } from "wagmi";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n";

/** Roboto */
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
/** Orbitron */
import "@fontsource/orbitron/400.css";
import "@fontsource/orbitron/600.css";
import "@fontsource/orbitron/700.css";
/** Tektur */
import "@fontsource/tektur/400.css";
import "@fontsource/tektur/600.css";
import "@fontsource/tektur/700.css";
/** Splitting */
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

/** Import global css */
import "./global.css";

const walletConnect = new WalletConnectService();
const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <RequestGateKeeper>
    <StateHydrator>
      <ReferralLinkValidator>
        <WagmiConfig config={walletConnect.getWagmiConfig()}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            {element}
          </QueryClientProvider>
        </WagmiConfig>
      </ReferralLinkValidator>
    </StateHydrator>
  </RequestGateKeeper>
);
