import { useEffect } from "react";
import { hasWindow } from "../../utils/windowUtils";
import { MOBILE_BREAKPOINT } from "../../constants/uiConstants";
import { screenSlice } from "../../store/appStore";

export interface WindowDimensions {
  isMobile: boolean;
  windowWidth: number;
  windowHeight: number;
}

export const DEFAULT_WINDOW_DIMENSIONS: WindowDimensions = {
  isMobile: false,
  windowWidth: 0,
  windowHeight: 0,
};

export const WindowResizeListener = () => {
  function getWindowDimensions(): WindowDimensions {
    if (!hasWindow()) {
      return DEFAULT_WINDOW_DIMENSIONS;
    }

    const { outerWidth: windowWidth, outerHeight: windowHeight } = window;

    return {
      isMobile: windowWidth <= MOBILE_BREAKPOINT,
      windowWidth,
      windowHeight,
    };
  }

  useEffect(() => {
    if (!hasWindow()) {
      return undefined;
    }

    function handleResize() {
      screenSlice().actions.setWindowDimensions(getWindowDimensions());
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return null;
};
