import { type StateCreator } from "zustand";
import { type AppStore } from "../appStore";

export interface PurchaseLevelConfig {
  level: number;
  levelCost: number;
}

export interface SmartContractSlice {
  purchaseLevelConfig?: PurchaseLevelConfig;
  actions: {
    openPurchaseDialog: (config: PurchaseLevelConfig) => void;
    closePurchaseDialog: () => void;
  };
}

export const createSmartContractSlice: StateCreator<any> = (set) => {
  return {
    actions: {
      openPurchaseDialog: (config: PurchaseLevelConfig) => {
        set((store: AppStore) => {
          store.smartContractSlice.purchaseLevelConfig = config;
        });
      },
      closePurchaseDialog: () => {
        set((store: AppStore) => {
          store.smartContractSlice.purchaseLevelConfig = undefined;
        });
      },
    },
  } as SmartContractSlice;
};
