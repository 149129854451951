import { type StateCreator } from "zustand";
import { type AppStore } from "../appStore";
import {
  DEFAULT_WINDOW_DIMENSIONS,
  type WindowDimensions,
} from "../../components/WindowResizeListener/WindowResizeListener";

export interface ScreenSlice {
  scrollDirection: "up" | "down";
  windowDimensions: WindowDimensions;
  actions: {
    setScrollDirection: (direction: "up" | "down") => void;
    setWindowDimensions: (dimensions: WindowDimensions) => void;
  };
}

export const createScreenSlice: StateCreator<any> = (set) => {
  return {
    scrollDirection: "down",
    windowDimensions: DEFAULT_WINDOW_DIMENSIONS,
    actions: {
      setScrollDirection: (direction: "up" | "down") => {
        set((store: AppStore) => {
          store.screenSlice.scrollDirection = direction;
        });
      },
      setWindowDimensions: (dimensions: WindowDimensions) => {
        set((store: AppStore) => {
          store.screenSlice.windowDimensions = dimensions;
        });
      },
    },
  } as ScreenSlice;
};
