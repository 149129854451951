import { useEffect, type ReactNode } from "react";
import { TELEGRAM_USER_KEY } from "../../constants/localStorageConstants";
import { authSlice } from "../../store/appStore";

interface StateHydratorProps {
  children: ReactNode;
}

export const StateHydrator = ({ children }: StateHydratorProps) => {
  useEffect(() => {
    /** Telegram login details */
    const persistedTelegramLogin = localStorage.getItem(TELEGRAM_USER_KEY);
    if (persistedTelegramLogin) {
      authSlice().actions.updateTelegramLogin(JSON.parse(persistedTelegramLogin));
    }
  }, []);

  return children;
};
