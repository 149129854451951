/* eslint-disable @typescript-eslint/naming-convention */
import { useState, type ReactNode, useEffect } from "react";
import { IpDataService } from "../../services/IpDataService/IpDataService";
import { hasWindow } from "../../utils/windowUtils";
import { ALLOW_CLIENT_IP_KEY } from "../../constants/localStorageConstants";

interface RequestGateKeeperProps {
  children: ReactNode;
}

const ALLOWED_COUNTRIES_SET = new Set([
  "Belarus",
  "India",
  "Indonesia",
  "Kazakhstan",
  "Moldova",
  "Nigeria",
  "Philippines",
  "Russia",
  "Ukraine",
  "Vietnam",
]);

export const RequestGateKeeper = ({ children }: RequestGateKeeperProps) => {
  /** Check for URL allowing unconditional access */
  if (hasWindow() && window.location.pathname === `/${process.env.GATSBY_SKIP_GEO_CHECK}`) {
    localStorage.setItem(ALLOW_CLIENT_IP_KEY, "true");
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  }

  const [isAllowed, setAllowed] = useState(handleCheckLocalStorage());

  function handleCheckLocalStorage() {
    if (!hasWindow()) {
      return null;
    }

    return localStorage.getItem(ALLOW_CLIENT_IP_KEY) as "true" | "false" | null;
  }

  useEffect(() => {
    if (isAllowed === null) {
      IpDataService.getIpData().then(({ country_name, threat }) => {
        const threats = Object.values(threat).filter((v) => v === true || v === false);

        const countryAllowed = ALLOWED_COUNTRIES_SET.has(country_name);
        const hasNoThreats = !threats.some((t) => t === true);

        if (countryAllowed && hasNoThreats) {
          setAllowed("true");
          localStorage.setItem(ALLOW_CLIENT_IP_KEY, "true");
        } else {
          localStorage.setItem(ALLOW_CLIENT_IP_KEY, "false");
        }
      });
    }
  }, [isAllowed]);

  if (isAllowed === "true") {
    if (hasWindow()) {
      /** Bring back coloring */
      document.body.style.backgroundColor = "var(--color-bg)";
    }

    return children;
  }

  return null;
};
