import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createScreenSlice, type ScreenSlice } from "./slices/screenSlice";
import { createSmartContractSlice, type SmartContractSlice } from "./slices/smartContractSlice";
import { type AuthSlice, createAuthSlice } from "./slices/authSlice";

export interface AppStore {
  authSlice: AuthSlice;
  screenSlice: ScreenSlice;
  smartContractSlice: SmartContractSlice;
}

export const useAppStore = create<AppStore>()(
  immer(
    devtools((...args) => ({
      authSlice: createAuthSlice(...args),
      screenSlice: createScreenSlice(...args),
      smartContractSlice: createSmartContractSlice(...args),
    })),
  ),
);

export const authSlice = () => useAppStore.getState().authSlice;
export const screenSlice = () => useAppStore.getState().screenSlice;
export const smartContractSlice = () => useAppStore.getState().smartContractSlice;
