export const MAX_PAGE_WIDTH = 1400;
export const MOBILE_BREAKPOINT = 800;
export const MOBILE_PAGE_PADDING = 22;
export const PAGE_PADDING = 80;

export const MOBILE_BREAKPOINT_MEDIA_QUERY = `@media (max-width: ${MOBILE_BREAKPOINT}px)`;
export const DESKTOP_BREAKPOINT_MEDIA_QUERY = `@media (min-width: ${MOBILE_BREAKPOINT}px)`;

export const MIN_HEADER_HEIGHT = 70;
export const HEADER_LOGO_HEIGHT = 24;
export const HEADER_BUTTON_ICON_SIZE = 30;

export const BACKGROUND_COLOR = "#030014";
