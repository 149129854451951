import { type TelegramUser } from "telegram-login-button";
import { type StateCreator } from "zustand";
import { type AppStore } from "../appStore";
import { TELEGRAM_USER_KEY } from "../../constants/localStorageConstants";
import { FirebaseService } from "../../services/FirebaseService/FirebaseService";

export interface AuthSlice {
  user?: TelegramUser;
  actions: {
    updateTelegramLogin: (user: TelegramUser) => void;
  };
}

export const createAuthSlice: StateCreator<any> = (set) => {
  return {
    actions: {
      updateTelegramLogin: (user) => {
        localStorage.setItem(TELEGRAM_USER_KEY, JSON.stringify(user));

        set((store: AppStore) => {
          store.authSlice.user = user;
        });

        /** Save user data to DB */
        FirebaseService.getInstance().registerWaitlistUser(user);
      },
    },
  } as AuthSlice;
};
