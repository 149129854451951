import { type FirebaseApp, initializeApp } from "firebase/app";
import {
  getFirestore,
  type Firestore,
  doc,
  setDoc,
  getDocs,
  collection,
  updateDoc,
  getCountFromServer,
  where,
  query,
  documentId,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { type TelegramUser } from "telegram-login-button";
import { type StagedTx, type LevelState, type WaitlistItem } from "./types";
import { getAllTreeNodes } from "../../utils/d3TreeUtils";
import {
  type ShortTxReceipt,
  type GameLevelBinaryTree,
} from "../GameLevelBinaryTree/GameLevelBinaryTree";
import { type TransactionReceipt } from "viem";
import { type PurchaseLevelConfig } from "../../store/slices/smartContractSlice";
import { getAccountLevelLookupDocKey, parsePlayerNode } from "./utils";
import { randomBytes, randomUUID } from "../../utils/randomUtils";
import { ethers } from "ethers";
import { type PlayerNode } from "../GameLevelBinaryTree/PlayerNode";

type Collection =
  | "waitlist"
  | "levels"
  | "receipts"
  | "account-level-lookup"
  | "our-wallets"
  | "staged-txs";

export class FirebaseService {
  private static instance: FirebaseService | null = null;
  private readonly app: FirebaseApp;
  private readonly db: Firestore;

  constructor() {
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
      measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
    };

    /** Initialize Firebase */
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.app);
  }

  public static getInstance(): FirebaseService {
    if (this.instance === null) {
      this.instance = new FirebaseService();
    }

    return this.instance;
  }

  private async getAllDocumentsFromCollection(targetCollection: Collection) {
    return await getDocs(collection(this.db, targetCollection));
  }

  private async setDoc(collection: Collection, key: string, val: any) {
    await setDoc(doc(this.db, collection, key), val);
  }

  public getDocumentRef(collection: Collection, key: string) {
    return doc(this.db, collection, key);
  }

  public async checkDocumentExists(targetCollection: Collection, key: string) {
    const snap = await getCountFromServer(
      query(collection(this.db, targetCollection), where(documentId(), "==", key)),
    );

    return !!snap.data().count;
  }

  public async registerWaitlistUser(telegramUser: TelegramUser) {
    const docKey = telegramUser.username;

    if (await this.checkDocumentExists("waitlist", docKey)) {
      return;
    }

    await this.setDoc("waitlist", docKey, {
      accessAllowed: false,
      telegram: telegramUser,
    } as WaitlistItem);
  }

  public async getAllWaitlistItems() {
    const querySnapshot = await this.getAllDocumentsFromCollection("waitlist");

    const waitlistItems: WaitlistItem[] = [];
    querySnapshot.forEach((doc) => {
      waitlistItems.push(doc.data() as any);
    });

    return waitlistItems;
  }

  public async updateWaitlistItem(key: string, value: Partial<WaitlistItem>) {
    await updateDoc(this.getDocumentRef("waitlist", key), value);
  }

  public async getWaitlistStateForUser(username: string) {
    const docRef = this.getDocumentRef("waitlist", username);
    const docSnap = await getDoc(docRef);

    return docSnap.data() as WaitlistItem | undefined;
  }

  public async updateLevel(levelNumber: number, gameClass: GameLevelBinaryTree) {
    if (gameClass.tree === null) {
      throw new Error("No root node");
    }

    const collection: Collection = "levels";
    const key = `level-${levelNumber}`;
    const payload = getAllTreeNodes(gameClass.tree).map((n) => parsePlayerNode(n));

    await this.setDoc(collection, key, {
      nodes: payload,
      bank: gameClass.bank,
      nodeCount: gameClass.nodeCount,
      playerCount: gameClass.playerCount,
      totalInvested: gameClass.totalInvested,
      totalPayedOut: gameClass.totalPayedOut,
    } as LevelState);
  }

  public async getLevelState(levelNumber: number) {
    const docRef = this.getDocumentRef("levels", `level-${levelNumber}`);
    const docSnap = await getDoc(docRef);

    return docSnap.data() as LevelState | undefined;
  }

  public async saveTransactionReceipt(
    receipt: TransactionReceipt,
    levelConfig: PurchaseLevelConfig,
  ) {
    const userKey = receipt.from;
    const payload = {
      blockHash: receipt.blockHash,
      blockNumber: receipt.blockNumber.toString(),
      contractAddress: receipt.contractAddress,
      cumulativeGasUsed: receipt.cumulativeGasUsed.toString(),
      effectiveGasPrice: receipt.effectiveGasPrice.toString(),
      from: receipt.from,
      gasUsed: receipt.gasUsed.toString(),
      status: receipt.status,
      to: receipt.to,
      transactionHash: receipt.transactionHash,
      transactionIndex: receipt.transactionIndex,
      type: receipt.type,
      level: levelConfig,
    };

    if (!(await this.checkDocumentExists("receipts", userKey))) {
      await this.setDoc("receipts", userKey, { transactions: [payload] });
      return;
    }

    const userTransactionsRef = this.getDocumentRef("receipts", userKey);
    await updateDoc(userTransactionsRef, { transactions: arrayUnion(payload) });
  }

  public async saveAccountLevelLookup(
    receipt: TransactionReceipt,
    levelConfig: PurchaseLevelConfig,
  ) {
    await this.setDoc(
      "account-level-lookup",
      getAccountLevelLookupDocKey(receipt.from, levelConfig.level),
      {},
    );
  }

  public async didUserPurchaseLevel(address: string, levelNumber: number) {
    return await this.checkDocumentExists(
      "account-level-lookup",
      getAccountLevelLookupDocKey(address, levelNumber),
    );
  }

  public async generateOurWallet(level: number): Promise<ShortTxReceipt> {
    const id = randomBytes(32).toString("hex");
    const privateKey = "0x" + id;
    const wallet = new ethers.Wallet(privateKey);

    await this.setDoc("our-wallets", wallet.address, { wallet: wallet.address, privateKey });

    return {
      from: wallet.address as `0x${string}`,
      transactionHash: "0xMINE",
      level,
    };
  }

  public async stageTxForPayment(nodeToRecycle: PlayerNode, reward: number, level: number) {
    const txId = randomUUID();

    await this.setDoc("staged-txs", txId, {
      player: parsePlayerNode(nodeToRecycle),
      reward,
      level,
      txId,
    } as StagedTx);
  }

  public async getStagedTxs() {
    const querySnapshot = await this.getAllDocumentsFromCollection("staged-txs");

    const stagedTxs: StagedTx[] = [];
    querySnapshot.forEach((doc) => {
      stagedTxs.push(doc.data() as StagedTx);
    });

    return stagedTxs;
  }

  public async getIsOurWalletAddress(address: string) {
    return await this.checkDocumentExists("our-wallets", address);
  }

  public async markTxAsPaid(txId: string) {
    await updateDoc(this.getDocumentRef("staged-txs", txId), { isPaid: true });
  }
}
