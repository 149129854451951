import { type RawNodeDatum } from "react-d3-tree";
import { type PlayerNode } from "../services/GameLevelBinaryTree/PlayerNode";

function getNodeName({ isCycleComplete, startPosition }: PlayerNode) {
  if (isCycleComplete) {
    return "Complete";
  }

  return `Player ${startPosition}`;
}

function getAttributesFromNode(node: PlayerNode) {
  if (node.isCycleComplete) {
    return undefined;
  }

  const attributes: Record<string, string | number | boolean> | undefined = {};

  attributes.Cycles = node.cyclesCount;
  attributes.Balance = node.totalReward.toFixed(2);

  return attributes;
}

function inOrderTraversal(node: PlayerNode | null): RawNodeDatum {
  if (node !== null) {
    const left = inOrderTraversal(node.left);
    const right = inOrderTraversal(node.right);
    return {
      name: getNodeName(node),
      children: [left, right],
      attributes: getAttributesFromNode(node),
    };
  }

  return { name: "EMPTY" };
}

export function getTreeDataFromBinaryTree(tree: PlayerNode): RawNodeDatum {
  return inOrderTraversal(tree);
}

export function getAllTreeNodes(tree: PlayerNode): PlayerNode[] {
  const result = [];
  result.push(tree);

  const children = [];
  if (tree.left) {
    children.push(tree.left);
  }
  if (tree.right) {
    children.push(tree.right);
  }

  children.forEach((child) => {
    const childResult = getAllTreeNodes(child);
    result.push(...childResult);
  });

  return result;
}
